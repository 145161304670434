<template>
  <v-container cols="12" class="pl-1 pr-1 mt-5 mb-5">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('orders.header') }}</v-list-item-title>
            <v-list-item-subtitle>
              <v-data-table
                dense
                :loading="loading"
                :loading-text="$t('loading')"
                :headers="headers"
                :items="orders"
                :footer-props="{
                  'items-per-page-options': [10],
                  'items-per-page-text': $t('table.per-page')
                }"
                :no-data-text="$t('table.no-data')"
                :items-per-page="10"
              >
                <template #[`item.status`]="{ item }">
                  {{ $t(`orders.status.${item.status}`) }}
                </template>
              </v-data-table>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderService from '@/services/OrderService.js';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      orders: [],
      loading: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('orders.field.order_no'),
          value: 'order_no',
          sortable: true
        },
        {
          text: this.$t('orders.field.client_ro'),
          value: 'client_ro.name',
          sortable: true
        },
        {
          text: this.$t('orders.field.status'),
          value: 'status',
          sortable: true
        }
      ];
    }
  },
  watch: {
    item: {
      handler() {
        this.loadOrders();
      },
      deep: true
    }
  },
  mounted() {
    this.loadOrders();
  },
  methods: {
    async loadOrders() {
      this.loading = true;

      const options = {
        filters: [
          {
            field: 'material_parts__id',
            operation: '=',
            value: this.item.id
          }
        ]
      };
      try {
        [[this.orders, this.total]] = await Promise.all([
          OrderService.getOrders(options, '')
        ]);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
